#root jobs-zero{
  .WMLAccordionZeroItemPod0 .JobTitleZeroView{
    flex: 1 0 auto;
  }
  .JobsZeroPod0Item0{
    .CardOnePod0{
      padding: var(--wml-spacing5);
    }
  }
  .JobsZeroPod1Item0{

    .WMLAccordionZeroItemPod{
      &0,&1{
        background: transparent;
        .JobTitleZeroView{
          margin: 0 0 0 var(--wml-spacing5)
        }
      }
    }
  }
  .JobsZeroPod1Item3 {
    .OverlayLoadingMainPod{
      width: 100%;
      height: 100%;
    }
  }
}
