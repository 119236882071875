
#root job-title-zero{
  .WMLFormPod0Item0{
    margin:0;
  }
  .WMLSelectZeroSelectPod0,.WMLSampleInfiniteDropdownItemMainItem1{
    background: transparent !important;
  }

  wml-select-zero {
    *{

      &::-webkit-scrollbar-track,&::-webkit-scrollbar-thumb{
        background: transparent;
      }
    }
    .WMLSelectZeroSelectPod0Text0{
      @include FontFamily1;
      font-weight: 900;
    }
    .WMLSampleInfiniteDropdownItemMainItem1{
      font-weight: 900;
      color:rgba(var(--wml-white));
    }
  }

}
