#root friends-zero-page{
  .FriendsZeroPagePod1Form0{
    @include MultiInputOneWMLField(110%);

    .MultiInputOnePod0{
      max-height: none;
      overflow: visible;
    }
  }
}
