#root video-manager-page{
  @media #{$tablet}{


    .VideoManagerPagePod0Item3{
      flex: 0 0 50%;
    }
    .VideoManagerPagePod0Item5{
      flex-direction: column;
    }
    .VideoManagerPagePod0Item6{
      flex: 1 0 auto;
    }

    .VideoManagerPagePod0Btn0{
      margin: var(--wml-spacing5) 0 0 0;
    }

    .VideoManagerPagePod0Item1{
      margin: var(--wml-spacing9) 0;
    }
  }
}
