#root video-data-editor-zero{
  @media #{$mobile}{
    .WMLFormMainPod{
      flex-direction: column;
    }
    .VideoDataEditorZeroPod1Item0 > form > .WMLFormPod0:nth-child(1), #root video-data-editor-zero .VideoDataEditorZeroPod1Item0 > form > .WMLFormPod0:nth-child(2){
      height: 50%;
    }
    .ContentEditorControlZeroPod0Item0 {
      .WMLFormPod0:nth-child(1){
        flex:0 0 auto;
      }
    }
    .ql-toolbar{
      overflow: auto hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
