#root video-data-editor-page{
  .VideoDataEditorPagePod1Btn0 button{
    padding: var(--wml-spacing3) var(--wml-spacing5);
  }
  .VideoDataEditorPagePod1Btn1 button{
    width: 100%;
  }

  .VideoDataEditorPagePod0Item0{
    .PageNumberControlZeroPod0Item1 p{
      @include FontFamily2;
      font-weight: bold;
    }
  }
}
