@font-face {
  font-family: 'PT-Mono';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/PT-Mono/PT-Mono.woff2') format('woff2'),
       url('../fonts/PT-Mono/PT-Mono.woff') format('woff');
}

@font-face {
  font-family: 'Component-Italic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Component-Italic/Component.woff2') format('woff2'),
       url('../fonts/Component-Italic/Component.woff') format('woff');
}

@font-face {
  font-family: 'Freelancer';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Freelancer/Freelancer.woff2') format('woff2'),
       url('../fonts/Freelancer/Freelancer.woff') format('woff');
}

@font-face {
  font-family: 'Quicksand-Book';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Quicksand-Book/Quicksand-Book.woff2') format('woff2'),
       url('../fonts/Quicksand-Book/Quicksand-Book.woff') format('woff');
}

@font-face {
  font-family: 'DT-Nightingale';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/DT-Nightingale/DT-Nightingale.woff2') format('woff2'),
       url('../fonts/DT-Nightingale/DT-Nightingale.woff') format('woff');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Barlow/Barlow.woff2') format('woff2'),
       url('../fonts/Barlow/Barlow.woff') format('woff');
}


@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: block;
  src:url('../fonts/Poppins/Poppins-VariableFont_wght.otf') format('truetype');
}

$display-xx-large:calc(72/16 * 1em);
$display-x-large:calc(64/16 * 1em);
$display-large:calc(56/16 * 1em);
$display:calc(40/16 * 1em);
$display-small:calc(32/16 * 1em);
$display-x-small:calc(28/16 * 1em);
$display-xx-small:calc(24/16 * 1em);


$body:calc(20/16 * 1em);
$body-bold:calc(20/16 * 1em);
$body-small:calc(16/16 * 1em);
$body-small-bold:calc(16/16 * 1em);

$button:calc(16/16 * 1em);
$text:calc(16/16 * 1em);

$overline:calc(18/16 * 1em);
$overline-bold:calc(18/16 * 1em);
$overline-small:calc(18/16 * 1em);

$caption:calc(14/16 * 1em);
$legal:calc(14/16 * 1em);

$wml-h1:calc(40/16 * 1em);
$wml-h2:calc(36/16 * 1em);
$wml-h3:calc(32/16 * 1em);
$wml-h4:calc(28/16 * 1em);
$wml-h5:calc(24/16 * 1em);
$wml-h6:calc(22/16 * 1em);
$wml-p: calc(20/16 * 1em);


:root {
  --wml-display-xx-large: #{$display-xx-large};
  --wml-display-x-large: #{$display-x-large};
  --wml-display-large: #{$display-large};
  --wml-display: #{$display};
  --wml-display-small: #{$display-small};
  --wml-display-x-small: #{$display-x-small};
  --wml-display-xx-small: #{$display-xx-small};

  --wml-body: #{$body};
  --wml-body-bold: #{$body-bold};
  --wml-body-small: #{$body-small};
  --wml-body-small-bold: #{$body-small-bold};

  --wml-button: #{$button};
  --wml-text: #{$text};
  --wml-overline: #{$overline};
  --wml-overline-bold: #{$overline-bold};
  --wml-overline-small: #{$overline-small};
  --wml-caption: #{$caption};
  --wml-legal: #{$legal};


  --wml-h1: #{$wml-h1};
  --wml-h2: #{$wml-h2};
  --wml-h3: #{$wml-h3};
  --wml-h4: #{$wml-h4};
  --wml-h5: #{$wml-h5};
  --wml-h6: #{$wml-h6};
  --wml-p: #{$wml-p};
}





