
#root account-layout{
  @media #{$mobile}{



    @include NavOneMobile;
    .AccountLayoutMainPod::-webkit-scrollbar{
      display: none;
    }

    .AccountLayoutMainPod{
      height: 100%;
      height: 100dvh;
    }
    .AccountLayoutPod0Item0{
      margin:0;
      width: 100%;
      padding: 0 var(--wml-spacing7);
    }
    .AccountLayoutPod0{
      position: sticky;
    }

    .AccountLayoutPod1{
      flex-direction: column;
      justify-content: flex-start;
    }

    .AccountLayoutPod1Item0{
      position: sticky;
      height: 50%;
      width: 100%;
    }
    .AccountLayoutPod1Item3{
      flex:0;
    }
    .AccountOverviewPagePod0Item0{
      margin: 0;
    }

  }
}
