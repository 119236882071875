#root transfer-recipients-form-zero{
  .CardOneMainPod{
    width: 80vw;
    height: 80vh;
    max-height: none;
  }

  .CardOnePod1{
    height: 100%;
    max-height: none;
    flex: 0 1 100%;
  }

  .TransferRecipientsFormZeroPod0Form0{
    flex: 1 1 80%;
    max-height: 80%;
    @include MultiInputOneWMLField
  }

  .TransferRecipientsFormZeroPod0Text0{
    white-space: break-spaces;
  }

}
