
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/quill/dist/quill.snow.css";
@import "node_modules/@windmillcode/quill-emoji/quill-emoji.css";

@import "assets/styles/static.css";


@import "src/assets/styles/global.scss";
@import "src/assets/styles/common.scss";
@import "src/assets/styles/media-queries.scss";
@import "src/assets/styles/layouts.scss";
@import "src/assets/styles/css.scss";



// app
@import "src/app/app.component.phone.scss";
@import "src/app/app.component.global.scss";

// TODO figure out a way to lazy load the css files
// not-found
@import "src/app/core/not-found/not-found.component.phone.scss";


// layouts
// account-layout
@import "src/app/layouts/account-layout/account-layout.component.global.scss";
@import "src/app/layouts/account-layout/account-layout.component.phone.scss";

// auth-layout
@import "src/app/layouts/auth-layout/auth-layout.component.global.scss";
@import "src/app/layouts/auth-layout/auth-layout.component.phone.scss";

// default-layout
@import "src/app/layouts/default-layout/default-layout.component.phone.scss";
@import "src/app/layouts/default-layout/default-layout.component.global.scss";

// landing-layout
@import "src/app/layouts/landing-layout/landing-layout.component.global.scss";
@import "src/app/layouts/landing-layout/landing-layout.component.phone.scss";

// pages
// account-overview-page
@import "src/app/pages/account-overview-page/account-overview-page.component.global.scss";
@import "src/app/pages/account-overview-page/account-overview-page.component.phone.scss";

// billing-zero-page
@import "src/app/pages/billing-zero-page/billing-zero-page.component.global.scss";
@import "src/app/pages/billing-zero-page/billing-zero-page.component.phone.scss";
@import "src/app/pages/billing-zero-page/billing-zero-page.component.tablet.scss";

// connection-hub-page
@import "src/app/pages/connection-hub-page/connection-hub-page.component.global.scss";
@import "src/app/pages/connection-hub-page/connection-hub-page.component.phone.scss";

// forgot-pass-page
@import "src/app/pages/forgot-pass-page/forgot-pass-page.component.global.scss";
@import "src/app/pages/forgot-pass-page/forgot-pass-page.component.phone.scss";

// friends-zero-page
@import "src/app/pages/friends-zero-page/friends-zero-page.component.global.scss";
@import "src/app/pages/friends-zero-page/friends-zero-page.component.phone.scss";

// guide-zero-page
@import "src/app/pages/guide-zero-page/guide-zero-page.component.global.scss";
@import "src/app/pages/guide-zero-page/guide-zero-page.component.phone.scss";
@import "src/app/pages/guide-zero-page/guide-zero-page.component.tablet.scss";

// legal-doc-page
@import "src/app/pages/legal-doc-page/legal-doc-page.component.global.scss";
@import "src/app/pages/legal-doc-page/legal-doc-page.component.phone.scss";

// sign-in-page
@import "src/app/pages/sign-in-page/sign-in-page.component.global.scss";
@import "src/app/pages/sign-in-page/sign-in-page.component.phone.scss";

// sign-up-page
@import "src/app/pages/sign-up-page/sign-up-page.component.global.scss";
@import "src/app/pages/sign-up-page/sign-up-page.component.phone.scss";

// survey-page
@import "src/app/pages/survey-page/survey-page.component.global.scss";
@import "src/app/pages/survey-page/survey-page.component.phone.scss";

// upload-zero-page
@import "src/app/pages/upload-zero-page/upload-zero-page.component.global.scss";
@import "src/app/pages/upload-zero-page/upload-zero-page.component.phone.scss";

// verify-email-zero-page
@import "src/app/pages/verify-email-zero-page/verify-email-zero-page.component.global.scss";
@import "src/app/pages/verify-email-zero-page/verify-email-zero-page.component.phone.scss";

// video-data-editor-page
@import "src/app/pages/video-data-editor-page/video-data-editor-page.component.global.scss";
@import "src/app/pages/video-data-editor-page/video-data-editor-page.component.phone.scss";

// video-manager-page
@import "src/app/pages/video-manager-page/video-manager-page.component.global.scss";
@import "src/app/pages/video-manager-page/video-manager-page.component.phone.scss";
@import "src/app/pages/video-manager-page/video-manager-page.component.tablet.scss";

// components
// add-new-video-one
@import "src/app/shared/components/add-new-video-one/add-new-video-one.component.global.scss";
@import "src/app/shared/components/add-new-video-one/add-new-video-one.component.phone.scss";

// ai-shorts-job-zero
@import "src/app/shared/components/ai-shorts-job-zero/ai-shorts-job-zero.component.global.scss";
@import "src/app/shared/components/ai-shorts-job-zero/ai-shorts-job-zero.component.phone.scss";

// backup-previous-state-job-zero
@import "src/app/shared/components/backup-previous-state-job-zero/backup-previous-state-job-zero.component.global.scss";
@import "src/app/shared/components/backup-previous-state-job-zero/backup-previous-state-job-zero.component.phone.scss";

// card-one
@import "src/app/shared/components/card-one/card-one.component.global.scss";
@import "src/app/shared/components/card-one/card-one.component.phone.scss";

// card-two
@import "src/app/shared/components/card-two/card-two.component.global.scss";
@import "src/app/shared/components/card-two/card-two.component.phone.scss";

// card-zero
@import "src/app/shared/components/card-zero/card-zero.component.global.scss";
@import "src/app/shared/components/card-zero/card-zero.component.phone.scss";

// confirm-dialog-zero
@import "src/app/shared/components/confirm-dialog-zero/confirm-dialog-zero.component.global.scss";
@import "src/app/shared/components/confirm-dialog-zero/confirm-dialog-zero.component.phone.scss";

// content-editor-control-zero
@import "src/app/shared/components/content-editor-control-zero/content-editor-control-zero.component.global.scss";
@import "src/app/shared/components/content-editor-control-zero/content-editor-control-zero.component.phone.scss";

// current-page-control-zero
@import "src/app/shared/components/current-page-control-zero/current-page-control-zero.component.global.scss";
@import "src/app/shared/components/current-page-control-zero/current-page-control-zero.component.phone.scss";

// dashboard-ctrl-zero
@import "src/app/shared/components/dashboard-ctrl-zero/dashboard-ctrl-zero.component.global.scss";
@import "src/app/shared/components/dashboard-ctrl-zero/dashboard-ctrl-zero.component.phone.scss";

// download-job-zero
@import "src/app/shared/components/download-job-zero/download-job-zero.component.global.scss";
@import "src/app/shared/components/download-job-zero/download-job-zero.component.phone.scss";

// dropdown-option-two
@import "src/app/shared/components/dropdown-option-two/dropdown-option-two.component.global.scss";
@import "src/app/shared/components/dropdown-option-two/dropdown-option-two.component.phone.scss";

// dropdown-two
@import "src/app/shared/components/dropdown-two/dropdown-two.component.global.scss";
@import "src/app/shared/components/dropdown-two/dropdown-two.component.phone.scss";

// filter-zero
@import "src/app/shared/components/filter-zero/filter-zero.component.global.scss";
@import "src/app/shared/components/filter-zero/filter-zero.component.phone.scss";

// friend-control-zero
@import "src/app/shared/components/friend-control-zero/friend-control-zero.component.global.scss";
@import "src/app/shared/components/friend-control-zero/friend-control-zero.component.phone.scss";

// insert-at-control-zero
@import "src/app/shared/components/insert-at-control-zero/insert-at-control-zero.component.global.scss";
@import "src/app/shared/components/insert-at-control-zero/insert-at-control-zero.component.phone.scss";

// interval-input-zero
@import "src/app/shared/components/interval-input-zero/interval-input-zero.component.global.scss";
@import "src/app/shared/components/interval-input-zero/interval-input-zero.component.phone.scss";

// job-status-zero
@import "src/app/shared/components/job-status-zero/job-status-zero.component.global.scss";
@import "src/app/shared/components/job-status-zero/job-status-zero.component.phone.scss";

// job-title-zero
@import "src/app/shared/components/job-title-zero/job-title-zero.component.global.scss";
@import "src/app/shared/components/job-title-zero/job-title-zero.component.phone.scss";

// jobs-zero
@import "src/app/shared/components/jobs-zero/jobs-zero.component.global.scss";
@import "src/app/shared/components/jobs-zero/jobs-zero.component.phone.scss";
@import "src/app/shared/components/jobs-zero/jobs-zero.component.tablet.scss";

// limits-zero
@import "src/app/shared/components/limits-zero/limits-zero.component.global.scss";
@import "src/app/shared/components/limits-zero/limits-zero.component.phone.scss";

// logo-img-zero
@import "src/app/shared/components/logo-img-zero/logo-img-zero.component.global.scss";
@import "src/app/shared/components/logo-img-zero/logo-img-zero.component.phone.scss";

// multi-input-one
@import "src/app/shared/components/multi-input-one/multi-input-one.component.global.scss";
@import "src/app/shared/components/multi-input-one/multi-input-one.component.phone.scss";

// nav-one
@import "src/app/shared/components/nav-one/nav-one.component.global.scss";
@import "src/app/shared/components/nav-one/nav-one.component.phone.scss";

// nav-zero
@import "src/app/shared/components/nav-zero/nav-zero.component.global.scss";
@import "src/app/shared/components/nav-zero/nav-zero.component.tablet.scss";
@import "src/app/shared/components/nav-zero/nav-zero.component.phone.scss";

// option-zero
@import "src/app/shared/components/option-zero/option-zero.component.global.scss";
@import "src/app/shared/components/option-zero/option-zero.component.phone.scss";

// page-number-control-zero
@import "src/app/shared/components/page-number-control-zero/page-number-control-zero.component.global.scss";
@import "src/app/shared/components/page-number-control-zero/page-number-control-zero.component.phone.scss";

// pricing-card-zero
@import "src/app/shared/components/pricing-card-zero/pricing-card-zero.component.global.scss";
@import "src/app/shared/components/pricing-card-zero/pricing-card-zero.component.phone.scss";

// pricing-zero
@import "src/app/shared/components/pricing-zero/pricing-zero.component.global.scss";
@import "src/app/shared/components/pricing-zero/pricing-zero.component.phone.scss";

// rich-text-editor-zero
@import "src/app/shared/components/rich-text-editor-zero/rich-text-editor-zero.component.global.scss";
@import "src/app/shared/components/rich-text-editor-zero/rich-text-editor-zero.component.phone.scss";

// search-zero
@import "src/app/shared/components/search-zero/search-zero.component.global.scss";
@import "src/app/shared/components/search-zero/search-zero.component.phone.scss";

// sort-zero
@import "src/app/shared/components/sort-zero/sort-zero.component.global.scss";
@import "src/app/shared/components/sort-zero/sort-zero.component.phone.scss";

// transfer-local-files-job-zero
@import "src/app/shared/components/transfer-local-files-job-zero/transfer-local-files-job-zero.component.global.scss";
@import "src/app/shared/components/transfer-local-files-job-zero/transfer-local-files-job-zero.component.phone.scss";

// transfer-recipients-form-zero
@import "src/app/shared/components/transfer-recipients-form-zero/transfer-recipients-form-zero.component.global.scss";
@import "src/app/shared/components/transfer-recipients-form-zero/transfer-recipients-form-zero.component.phone.scss";

// update-videos-job-zero
@import "src/app/shared/components/update-videos-job-zero/update-videos-job-zero.component.global.scss";
@import "src/app/shared/components/update-videos-job-zero/update-videos-job-zero.component.phone.scss";

// video-data-editor-zero
@import "src/app/shared/components/video-data-editor-zero/video-data-editor-zero.component.global.scss";
@import "src/app/shared/components/video-data-editor-zero/video-data-editor-zero.component.phone.scss";

// video-tile-zero
@import "src/app/shared/components/video-tile-zero/video-tile-zero.component.global.scss";
@import "src/app/shared/components/video-tile-zero/video-tile-zero.component.phone.scss";

// video-viewer-zero
@import "src/app/shared/components/video-viewer-zero/video-viewer-zero.component.global.scss";
@import "src/app/shared/components/video-viewer-zero/video-viewer-zero.component.phone.scss";


// media-query-marker (IMPT DONT DELETE THIS LINE)


:root {

  @function provideRandomColor() {
    $result: rgba(random(255), random(255), random(255), random(1));

    @return $result;
  }

  @function contrastColor($color, $dark, $light) {
    $result: if(lightness($color) < 45%, $light, $dark);

    @debug "#{$result}";
    @return var($result)
  }

  @function prefixClass($class) {
    @return ""+$class;
  }

  @function hex-to-filter($hex-color) {
    $r: red($hex
    -color);
    $g: green($hex-color);
    $b: blue($hex-color);
    $filter:  invert(100%) rgba(#{$r}, #{$g}, #{$b}, 1);
    @return $filter;
  }



  font-size: 10.6px;
  // previous size was 16
  color: rgba(var(--wml-white));


  @media #{$mobile}{
    font-size: calc(2vw);
  }

  @media #{$mobileFont}{
    font-size: calc(2vw);
  }



  @keyframes GlobalImg0Sping {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index:5;
    @include ModernScrollbar0;
    &:not(i.fa-solid){
      @include FontFamily1;
    }
  }

  &:not(h1,h2,h3,h4,h5,h6,span,p){
    transition: all 1s;
  }

  h1,h2,h3,h4,h5,h6,span,p{
    transition: none;
    color: rgba(var(--wml-white))
  }

  a {
    @include GlobalLink0;
  }


  input:not([type='checkbox']),
  select,
  textarea {
    width: 100%;
  }

  input:not([type='checkbox']){

    word-wrap: break-word;
    display: block;
    font-size: inherit;
    border-radius: calc(6/16 * 1em);

    outline: none;
    margin: 0;
    padding: 0;
    transition: all 1s;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

    width: 100%;
  }

  textarea{

    font-size: inherit;
    border-radius: calc(6/16 * 1em);
    outline: none;
    border: calc(2/16 * 1em) inset rgb(118,118,118);
    padding: var(--wml-spacing4);
    display: block;
    margin: 0;
    min-height: calc(40/16 * 1em);
    height: calc(140/16 * 1em);
    transition: all 1s;
    width: 100%;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  select {
    appearance: none;
  }

  li {
    list-style: none;
  }



  img{
    width: 100%;
    height: auto;
  }

  button {
    background-color: transparent;
  }

  html,body{
    background-color: rgba(var(--wml-primary));

  }

  mat-slide-toggle{
    label{
      color: rgba(var(--wml-white));
    }
  }

  h1{
    font-size:var(--wml-h1)
  }
  h2{
    font-size:var(--wml-h2)
  }
  h3{
    font-size:var(--wml-h3)
  }
  h4{
    font-size:var(--wml-h4)
  }
  h5{
    font-size:var(--wml-h5)
  }
  h6{
    font-size:var(--wml-h6)
  }
  p{
    font-size:var(--wml-p)
  }

  .skip-link {
    position: absolute;
    top: calc(-40/10.6 * 1em);
    left: 0;
    background: rgba(var(--wml-black));
    color: rgba(var(--wml-white));
    padding: calc(8/10.6 * 1em);
    z-index: 100;
    text-decoration: none;
  }

  .skip-link:focus {
    top: 0;
  }

  .WMLPopupZeroView *{
    white-space: break-spaces;
  }



}

