#root video-manager-page{
  @media #{$mobile}{
    .VideoManagerPageMainPod{
      flex-direction: column;
      justify-content: flex-start;
      max-height: 100%;
    }
    .VideoManagerPagePod0{
      order: 1;
      flex: 0 1  100%;
      height: 100%;
    }
    .VideoManagerPagePod0Item0{
      position: relative;
    }
    .VideoManagerPagePod0Item1{
      display: none;
    }
    .VideoManagerPagePod1{
      order: 0;
      flex: 0 1 auto;
      height: auto;
      align-items: flex-start;
    }
    .VideoManagerPagePod0Item3{
      flex: 0 0 100%;
    }
    .VideoManagerPagePod1Item1{
      display: flex;
      justify-content: space-between;
      .CurrentPageControlZeroPod0Item0{
        justify-content: flex-end;
      }
    }
    .VideoManagerPagePod0Item2{
      overflow: hidden auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }


    #VideoManagerPage_playlistsFilter::-webkit-scrollbar{
      display: none;
    }
    .VideoManagerPageMainPod{
      margin: var(--wml-spacing5) 0 0 0;
    }

    .VideoManagerPageplaylistsCard{
      .CardOnePod1{
        flex: 1 0 100%;
        max-height: 30em;
      }
    }


    .VideoManagerPagePod1Item4{
      .VideoManagerPagePod0Btn0 {
        margin:var(--wml-spacing5) 0;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .VideoManagerPageplaylistsCard .CardOnePod1{
      max-height: none;
    }
  }
}
