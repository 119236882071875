#root upload-zero-page{
  .UploadZeroPagePod1Btn0{
    .WMLButtonOneMainButton{
      @for $i from 0 through 3 {
        &#{$i} {
          width: 100%;
        }
      }
    }
  }
}
