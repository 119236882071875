#root jobs-zero{
  @media #{$tablet}{
    .JobTitleZeroPod0{
      flex-direction: column;
      wml-button-one{
        button{
          margin:var(--wml-spacing3);
          width: 100%;
        }
      }
    }
  }
}
