#root search-zero{
  .SearchZeroPod0Btn0{
    .WMLButtonOneMainButton{
      &0{

        border-radius: 0;
        border: none;
        background-color: rgba(var(--wml-tertiary));
        width: 100%;
        height: 100%;
        padding:0;
        &:hover,&Selected0{
          background-color: rgba(var(--wml-primary));
          border-color:transparent;
          i {
            color: rgba(var(--wml-white)) !important;
          }
        }
      }
    }
  }
  .SearchZeroPod0Input0{
    input{
      @include Input1;
    }
  }
}
