#root default-layout{
  @media #{$mobile}{
    @include NavZeroMobile;

    * ::-webkit-scrollbar {
      display: none;
    }

    &.DefaultLayoutView{
      height: 100dvh;
    }
    .DefaultLayoutMainPod{
      height: 100dvh;
    }
    .DefaultLayoutPod1{
      padding: 0;
    }
  }
}
