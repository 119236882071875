#root video-data-editor-page{
  @media #{$mobile}{
    .VideoDataEditorPagePod0{
      flex-direction: column;
    }
    .VideoDataEditorPagePod0Item0{
      margin: var(--wml-spacing7) 0 0 0;
      height: auto;
      width: 100%;
      display: flex;
    }

    .VideoDataEditorPagePod0Item1{
      flex: 1 1 auto;
    }

    .PageNumberControlZeroPod0Item1{
      overflow: hidden auto;
      align-items: flex-start;

    }
    .PageNumberControlZeroView{
      height: auto;
    }
    .PageNumberControlZeroPod0Item0{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(0, 50%));
      grid-auto-rows: 1fr;
      align-items: stretch;
      min-height: calc(100/10.6 * 1em);

    }
    .PageNumberControlZeroPod0Item1{
      width: 100%;
    }
    .VideoDataEditorPagePod1Item0{
      flex-direction: column;
    }
    .VideoDataEditorPagePod1Item1{
      margin:0
    }
    .VideoDataEditorPagePod2Text0{
      overflow:auto hidden
    }

  }
}
