#root billing-zero-page{
  @media #{$mobile}{

    .BillingZeroPagePod0Btn0{
      margin: var(--wml-spacing7) 0;
      align-self: center;
      position: relative;
    }
    .BillingZeroPagePod0,.BillingZeroPagePod1, .BillingZeroPagePod2{
      flex-direction: column;
      display: flex;
    }
    .BillingZeroPagePod1{
      flex: 1 1 50%
    }
    .BillingZeroPagePod1Item1{
      margin:var(--wml-spacing11) 0 ;
    }
    .BillingZeroPagePod1Item0{
      flex: 1 1 40%
    }
    .BillingZeroPagePod1Item1{
      flex: 1 0 60%
    }
  }
}
