#root forgot-pass-page{
  .ForgotPassPageMainItem0{
    height: 95%;
    width: 95%;
  }
  .ForgotPassPagePod0 {
    & > *{
      width: 100%;
    }
    flex: 0 0 auto;
  }
  .ForgotPassPagePod0Form0{
    width: 75%;
  }
  .ForgotPassPagePod0Btn0 .WMLButtonOneMainButton1{

    width: 75%;
    padding-top: var(--wml-spacing5) ;
    padding-bottom: var(--wml-spacing5) ;

  }

}
