#root sign-up-page{
  .SignUpPagePod0Form0{
    width: 75%;
  }
  .SignUpPagePod0Btn0 .WMLButtonOneMainButton1{

    width: 100%;
    padding-top: var(--wml-spacing5) ;
    padding-bottom: var(--wml-spacing5) ;

  }
  .SignUpPagePod2Btn0{
    &Btn{
      width: 100%;
      padding-top: var(--wml-spacing5) ;
      padding-bottom: var(--wml-spacing5) ;
    }
    &Google {
      background: rgba(var(--wml-google-primary));
      *{
        color:rgba(var(--wml-orig-black))
      }
      &:hover{
        .WMLButtonZeroMainButton0Text0{
          color: rgba(var(--wml-orig-black))
        }
      }
    }
    &Facebook {
      background: rgba(var(--wml-facebook-primary));
    }
    &Apple {
      background: rgba(var(--wml-apple-primary));
    }
    &Twitter {
      background: rgba(var(--wml-twitter-primary));
    }
  }
}
