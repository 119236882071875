#root upload-zero-page{
  @media #{$mobile}{
    .UploadZeroPageMainPod{
      flex-direction: column;
    }

    .UploadZeroPagePod{
      @for $i from 0 through 1 {
        &#{$i} {
          flex: 0 0 auto;
          margin: var(--wml-spacing7) 0 0 0
        }
      }
    }

  }
}
