#root sign-up-page{
  @media #{$mobile}{
    .SignUpPageMainItem0{
      height: 95%;
      padding: var(--wml-spacing7);
      display: flex;
      flex-direction: column;
    }
    .SignUpPageMainItem1{
      flex: 1 1 auto;
      flex-direction: column;
      overflow: hidden auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    .SignUpPagePod0,.SignUpPagePod1,.SignUpPagePod2{
      padding: 0;
    }
    .SignUpPagePod2{
      flex: 1 1 auto;
    }
    .SignUpPagePod1{
      flex-direction: row;
    }
    .SignUpPagePod1StrikeThru0{
      height: calc(1/10.6 * 1em);
    }
    .SignUpPagePod0Btn0,.SignUpPagePod0Form0,.SignUpPagePod2Btn0{
      width: 100%;
    }


  }
}
