#root sign-in-page{
  @media #{$mobile}{
    .SignInPageMainItem0{
      height: 95%;
      padding: var(--wml-spacing7);
      display: flex;
      flex-direction: column;
    }
    .SignInPageMainItem1{
      flex: 1 1 auto;
      flex-direction: column;
      overflow: hidden auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    .SignInPagePod0,.SignInPagePod1,.SignInPagePod2{
      padding: 0;
    }
    .SignInPagePod2{
      flex: 1 1 auto;
    }
    .SignInPagePod1{
      flex-direction: row;
    }
    .SignInPagePod1StrikeThru0{
      height: calc(1/10.6 * 1em);
    }
    .SignInPagePod0Btn0,.SignInPagePod0Form0,.SignInPagePod2Btn0{
      width: 100%;
    }
    .SignInPagePod0Text1,.SignInPagePod0Link0{
      text-align: center;
    }

  }
}
