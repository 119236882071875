// @import './variables';


// // Emoji styles & image sheets sourced from: https://github.com/markygriff/Website.git
// // TODO: Update with a more contemporary sprite and css


.ap {
  display: inline-flex;
  background-size: 820px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  font-size: calc(20/10.6 * 1em);
  line-height: 1;
  overflow: hidden;
  margin-top: -3px;
}

