#root nav-zero{
  .NavZeroPod2Item1{
    display: flex;
    justify-content: center;


    .WMLInfiniteDropdownMainPod{
      width: 200%;
      flex: 1 0 200%;
      background-color: rgba(var(--wml-primary));
      border: calc(1/10.6 * 1em) solid rgba(var(--wml-white));
      padding: var(--wml-spacing5) 0;
    }
    .WMLInfiniteDropdownMainItem0{
      padding: 0  0 0 var(--wml-spacing7) ;
    }
  }
}
