
#root{

}
#root account-overview-page{
  @media #{$mobileFont}{
    font-size: calc(3vw);
  }
  .AccountOverviewPagePod1Item0{
    wml-form{
      .WMLFormPod0{

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            display: flex;
            .WMLFormPod0Item0{
              flex: 0 0 100%;
            }
          }
        }

      }
    }
  }

  .AccountOverviewPagePod1Item1{
    wml-form{
      .WMLFormPod0{

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            display: flex;
            .WMLFormPod0Item0{
              flex: 0 0 100%;
            }
          }
        }

      }
    }
  }

}
