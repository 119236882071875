
#root multi-input-one{

  // TODO for  some strange reason it has to bee like this
  @media #{$mobile}{
    @include MultiInputOneWMLButtonOne;
    font-size: calc(1vw);
  }

  @media #{$mobileFont}{
    @include MultiInputOneWMLButtonOne;
    font-size: calc(2vw);
  }

  @media #{$mobile}{

    .MultiInputOnePod1{
      flex-direction: column;
      align-items: center !important;
    }

    .MultiInputOnePod1Btn{
      &0,&1{
        margin: var(--wml-spacing5) 0 0 0;
        width: 100%;
        button{
          width: 100%;
        }
      }
    }

  }
}
