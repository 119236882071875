#root landing-layout{
  @media #{$mobile}{

    .LandingLayoutPod1Default{
      display: none;
    }
    .LandingLayoutPod1Mobile{
      display: flex;
    }
    .LandingLayoutMainPod{
      display: block;
    }

    .LandingLayoutPod0{
      height: 100%;
      height: 100dvh;
    }
    .LandingLayoutPod1{
      flex: 0 0 50%
    }

    // .LandingLayoutPod1Item1,.LandingLayoutPod0Item1{
    //   flex: 0 1 45%;
    // }
    .LandingLayoutPod1Item0{
      flex: 0 0 250%;
      height: 200%;
    }
    .LandingLayoutPod1Title0{
      font-size: var(--wml-display-x-small);
    }
    .LandingLayoutPod1Text0{
      font-size: var(--wml-text);
    }
    .LandingLayoutPod0Item0{
      flex:0 1 auto;
    }
    .LandingLayoutPod1Item3{
      justify-content: flex-start;
    }
    .LandingLayoutPod1Item5{
      flex: 1 1 65%;
    }
    .LandingLayoutPod1Icon0{
      width:  calc(22.5/10.6 * 1em);
      height: calc(22.5/10.6 * 1em);
    }


    .LandingLayoutPod0Img0,.LandingLayoutPod0Img1{
      width: 100%;
      height: auto;
    }
  }
}
