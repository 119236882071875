#root guide-zero-page{
  @media #{$mobile}{
    .GuideZeroPagePod0{
      height: calc(400/10.6 * 1em);
    }
    .GuideZeroPageMainItem0{
      display: block;
    }
    .GuideZeroPageMainItem0{
      height: auto;
    }
    .GuideZeroPageMainPod{
      align-items:start;
    }
  }
}
