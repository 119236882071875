#root video-manager-page{
  .fa-solid.fa-grip{
    color:rgba(var(--wml-white));
  }
  .VideoManagerPagePod0Item2{

    .CardOneMainPod,.CardOnePod1{
      max-height: none;
    }

    .CardOnePod1{
      margin:var(--wml-spacing3) 0 0 0;
    }

    .CardOnePod0Text0{
      height: calc(30/10.6 * 1em);
      overflow: hidden auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    video-tile-zero{
      height: calc(450/10.6 * 1em);
    }



  }

  .VideoManagerPageplaylistsCard{
    flex:1 0 auto;

    .CardOneMainPod{
      height: 100%;
      max-height: none;
    }
    .CardOnePod1{
      flex: 1 1 100%;
      max-height: 130em;
    }
  }
}
