#root landing-layout{
  @media #{$mobileFont}{
    font-size: calc(3vw);
  }

  .LandingLayoutPod1Default{
    display: flex;
    // .LandingLayoutPod1Item0{
    //   @include outline1;
    // }
  }
  .LandingLayoutPod1Mobile{
    display: none;
  }


}
