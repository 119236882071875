#root account-overview-page{
  @media #{$mobile}{

    .AccountOverviewPagePod1Item0,.AccountOverviewPagePod2Item0{
      flex-direction: column;
    }

    .AccountOverviewPagePod1Item3{
      width: 100%;
    }

    .AccountOverviewPageSharedLine1{
      height: calc(1/10.6 * 1em);
      width: 100%;
      margin: var(--wml-spacing5) 0 ;
    }

    .AccountOverviewPagePod1Item2{
      .WMLFormMainPod{
        display: flex;
        justify-content: space-between;
      }
    }

    .AccountOverviewPagePod2Item2 > *{
      margin: var(--wml-spacing7) 0 0 0;
    }


  }
}
#root{
  @media #{$mobile}{
    #AccountOverviewPage_Pricing{
      .WMLPopupZeroMainPod{
        height: 100%;
      }
      .WMLPopupZeroPod0{
        width: 100%;
        height: 100%;
      }
    }
  }
}
