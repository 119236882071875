#root video-data-editor-zero{
  .WMLFormMainPod{
    height: 100%;
    width: 100%;
    display: flex;
  }

  .WMLFormPod0{
    height: 100%;

  }
  @media #{$desktop,$tablet}{


    .VideoDataEditorZeroPod1Form0 > .WMLFormMainPod {
      > .WMLFormPod0{
        padding: 0 var(--wml-spacing5)
      }
      > .WMLFormPod0:nth-child(1){
        flex: 0 0 30%;

        .OptionZeroPod0Text0{
          white-space: break-spaces
        }
      }
      > .WMLFormPod0:nth-child(2){
        flex: 0 0 10%;
      }
      > .WMLFormPod0:nth-child(3){
        padding: 0 var(--wml-spacing11) ;
        flex: 1 1 60%;
        // max-width: 60%;
      }

      .ContentEditorControlZeroPod0Item0 {
        .WMLFormPod0:nth-child(1){
          flex:1 1 auto;
        }
      }
    }
  }

  #VideoDataEditorZero_videosField,#VideoDataEditorZero_typeField{
    .WMLOptionsMainPod{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;

      .WMLFieldPod1{
        overflow: hidden auto;
      }

      wml-options-zero-item{
        margin: var(--wml-spacing7) 0 0 0;
      }
    }
  }

  #VideoDataEditorZero_contentField{
    display: block;
    height: 100%;
    .WMLFormPod0Item0,.WMLFieldMainPod{
      height: 100%;
    }
    .WMLFieldMainPod{
      display: flex;
      flex-direction: column;
      .WMLFieldPod1{
        flex: 1 0 auto;
      }
    }
  }

  #VideoDataEditorZero_insertField{
    .InsertAtControlZeroView{
      margin: var(--wml-spacing5) 0 0 0;
    }
  }

}
